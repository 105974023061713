import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';

const firebaseConfig = {
    apiKey: "AIzaSyDrWqp-wLgeyte_3Un9o63xddDAc9u8nmw",
    authDomain: "okitect-95660.firebaseapp.com",
    projectId: "okitect-95660",
    storageBucket: "okitect-95660.appspot.com",
    messagingSenderId: "267031094425",
    appId: "1:267031094425:web:a55cfb283eba44786c0408",
    measurementId: "G-RB6DC23X6J"
};

firebase.initializeApp(firebaseConfig);

export default function RegisterForm() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyNiche, setCompanyNiche] = useState('');
    const [role, setRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Create a new user in Firebase Auth
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;

            // Create a new user document in Firestore
            await firebase.firestore().collection('users').doc(user.uid).set({
                firstName,
                lastName,
                email,
                companyNiche,
                role,
            });

            console.log(user);
            navigate('/dashboard', { replace: true });
        } catch (error) {
            alert(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField name="firstName" label="First name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <TextField name="lastName" label="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextField name="password" label="Password" type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} InputProps={{ endAdornment: (<InputAdornment position="end"><IconButton onClick={() => setShowPassword(!showPassword)} edge="end"><Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /></IconButton></InputAdornment>), }} />
                    <TextField name="companyNiche" label="Company niche" value={companyNiche} onChange={(e) => setCompanyNiche(e.target.value)} />
                    <TextField name="role" label="Role" value={role} onChange={(e) => setRole(e.target.value)} />
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <Checkbox name="remember" label="Remember me" />
                    <Link variant="subtitle2" underline="hover">
                        Forgot password?
                    </Link>
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading} disabled={isLoading}>
                    Register
                </LoadingButton>
            </form>
        </>
    );
}