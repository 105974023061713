import React, { useState, useEffect } from 'react';
import {
  Container,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDrWqp-wLgeyte_3Un9o63xddDAc9u8nmw",
  authDomain: "okitect-95660.firebaseapp.com",
  projectId: "okitect-95660",
  storageBucket: "okitect-95660.appspot.com",
  messagingSenderId: "267031094425",
  appId: "1:267031094425:web:a55cfb283eba44786c0408",
  measurementId: "G-RB6DC23X6J"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

const ManageUsers = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentUserUid, setCurrentUserUid] = useState('');
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    // Get the current user's UID
    const user = firebase.auth().currentUser;
    if (user) {

      const fetchManagers = async () => {
        console.log(user.uid)
        const managersRef = firestore.collection(`users/${user.uid}/managers`);
        const snapshot = await managersRef.get();
        const managersData = snapshot.docs.map((doc) => doc.data());
        setManagers(managersData);
      };

      if (user.uid) {
        fetchManagers();
      }

      setCurrentUserUid(user.uid);
    }
  }, []);

  const handleRemoveUser = (index) => {
    const managerToRemove = managers[index];

    // Remove the manager from Firestore
    firestore
      .collection(`users/${currentUserUid}/managers`)
      .where('email', '==', managerToRemove.email)
      .where('password', '==', managerToRemove.password)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref.delete();
        });
        console.log('User removed successfully!');
      })
      .catch((error) => {
        console.error('Error removing user: ', error);
      });
  };


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAddUser = () => {
    // Create a new user object with email and password
    const newUser = {
      email: email,
      password: password,
    };

    // Save the new user to Firebase Firestore
    firestore.collection(`users/${currentUserUid}/managers`).add(newUser)
      .then(() => {
        console.log('User added successfully!');
      })
      .catch((error) => {
        console.error('Error adding user: ', error);
      });

    // Reset form values
    setEmail('');
    setPassword('');

    // Close the dialog
    handleCloseDialog();
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Manage your users
        </Typography>
        <Button variant="contained" onClick={handleOpenDialog}>
          + Add users
        </Button>
      </Stack>

      <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
        {/* Your existing components */}
      </Stack>

      <Grid container spacing={3}>
        {/* Your existing components */}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddUser} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <List>
        {managers.map((manager, index) => (
          <ListItem key={index}>
            <ListItemText primary={manager.email} secondary={manager.password} />
            <Button onClick={() => handleRemoveUser(index)} color="secondary">
              Remove
            </Button>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default ManageUsers;
