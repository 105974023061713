import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, onSnapshot, collection } from 'firebase/firestore';
import { nextDay } from 'date-fns';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate();


  const [tableData, setTableData] = useState([]);
  const [columnsNumbers, setColumnNumbers] = useState(0);
  const [ownerId, setOwnerId] = useState("0");
  const [managersData, setManagersData] = useState([]);
  const [managersList, setManagersList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const firestore = getFirestore();

      // Wait for the user authentication state to change
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;

          const path = `users/${uid}`;


          const userDocRef = doc(firestore, path);

          onSnapshot(userDocRef, (docSnapshot) => {
            const userData = docSnapshot.data();
            // Handle the user data here
            console.log(userData['ownerId']);
            let ownerPath = `users/${userData['ownerId']}`;
            const ownerDocRef = doc(firestore, ownerPath);
            onSnapshot(ownerDocRef, (docSnapshot) => {
              const ownerData = docSnapshot.data();

              setTableData(ownerData["table_data"]);
              setColumnNumbers(Object.keys(ownerData["table_data"][0]).length - 1);
            });

            const managersPath = `users/${userData['ownerId']}/managers`;

            const managersCollectionRef = collection(firestore, managersPath);

            onSnapshot(managersCollectionRef, (querySnapshot) => {
              const managersList = querySnapshot.docs.map((doc) => doc.data());
              setManagersList(managersList); // Set managersData here
            });


            onSnapshot(managersCollectionRef, (querySnapshot) => {

              setManagersData(querySnapshot);
              querySnapshot.forEach((doc) => {
                const managerData = doc.data();
                // Handle each manager's data here
                console.log(managerData);
              });
            });

          });

          setOwnerId(uid)




        }
        else {
          navigate("/login");
        }
      });
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Okitect - Data protection platform </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" >
          Hi, Welcome back
        </Typography>
        <Typography variant="p" >
          This is your <b>Manager ID: {ownerId}</b>
        </Typography>

        <Grid container sx={{ mt: 4 }} spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Rows" total={tableData.length} icon={'ant-design:insert-row-above-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Colums" total={columnsNumbers} color="info" icon={'ant-design:insert-row-left-outlined'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Managers" total={managersData.size} color="warning" icon={'ant-design:user'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={1} color="error" icon={'ant-design:bug-filled'} />
          </Grid>


          <Grid item xs={12} md={6} lg={6}>
            <AppNewsUpdate
              title="Your team"
              // Map your managers and pass the data to the list prop
              list={managersList.map((manager) => ({
                id: manager.email,
                title: manager.email,
                description: "",
                image: `https://ucarecdn.com/606e1232-ab8e-4bc1-a1d1-550375ed6adc/`, // You can use your image URL here
                postedAt: faker.date.recent(), // Set an appropriate date or timestamp
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <h3>Navigating Okitect</h3>
            <iframe width="100%" height="80%" style={{ borderRadius: "10px", minHeight: "400px" }} src="https://streamable.com/e/knpcw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppTasks
              title="Tasks"
              list={[

              ]}
            />
          </Grid>




        </Grid>
      </Container>
    </>
  );
}
