import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore, collection, addDoc, deleteDoc, updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, currentUser } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDrWqp-wLgeyte_3Un9o63xddDAc9u8nmw",
  authDomain: "okitect-95660.firebaseapp.com",
  projectId: "okitect-95660",
  storageBucket: "okitect-95660.appspot.com",
  messagingSenderId: "267031094425",
  appId: "1:267031094425:web:a55cfb283eba44786c0408",
  measurementId: "G-RB6DC23X6J"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore(); // Get a reference to the Firestore database
const auth = getAuth();

const EditableTable = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userIdParam = urlParams.get('id');

  const [userId, setUserId] = useState(null);
  const [tableData, setTableData] = useState([
    { id: 1, name: 'John', age: 25 },
    { id: 2, name: 'Jane', age: 30 },
    { id: 3, name: 'Tom', age: 40 },
  ]);
  const [columnCount, setColumnCount] = useState(Object.keys(tableData[0]).length);
  const [rowCount, setRowCount] = useState(tableData.length);
  const [allowedDuplicates, setAllowedDuplicates] = useState(0); // Initialize with a default value
  const [userEmail, setUserEmail] = useState(null)
  // Other state and functions...

  useEffect(() => {
    if (userIdParam) {
      setUserId(userIdParam);
      fetchTableData(userIdParam);
    } else {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          const email = user.email; // Add this line to get the user's email
          setUserEmail(email);
          const path = `users/${uid}`;
          const userDocRef = doc(db, path);

          onSnapshot(userDocRef, (docSnapshot) => {
            const userData = docSnapshot.data();
            setUserId(userData['ownerId']);
            fetchTableData(userData['ownerId']);
          });
        } else {
          alert("You are not logged in. Navigate to managers.okitect.com/login");
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [userIdParam]);
  const handleDuplicatesChange = (e) => {
    const duplicates = parseInt(e.target.value);
    setAllowedDuplicates(duplicates);
  };

  const handleCellChange = (e, rowId, field) => {
    const updatedData = tableData.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setTableData(updatedData);
  };


  const fetchTableData = (userId) => {
    db.collection('users')
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data)
          if (data && data.table_data) {
            const tableData = data.table_data;
            setAllowedDuplicates(data.duplicates)
            setTableData(tableData);
            setRowCount(tableData.length);
            setColumnCount(Object.keys(tableData[0]).length);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching table data:', error);
      });
  };

  const handleAddRow = () => {
    const newRow = { id: rowCount + 1 };
    const columns = Object.keys(tableData[0]);
    columns.forEach((column) => {
      if (column !== 'id') {
        newRow[column] = '';
      }
    });
    newRow.manager = [userEmail]
    const updatedData = [...tableData, newRow];
    setTableData(updatedData);
    setRowCount(rowCount + 1);
  };


  const handleDeleteRow = (rowId) => {
    const updatedData = tableData.filter((row) => row.id !== rowId);
    setTableData(updatedData);
    setRowCount(rowCount - 1);
  };

  const handleColumnChange = (e, columnName) => {
    const updatedData = tableData.map((row) => {
      if (row[columnName] !== undefined) {
        const updatedRow = { ...row };
        updatedRow[columnName] = e.target.value;
        return updatedRow;
      }
      return row;
    });
    setTableData(updatedData);
  };

  const handleAddColumn = () => {
    const columnName = `Column ${columnCount + 1}`;
    const updatedData = tableData.map((row) => ({
      ...row,
      [columnName]: '',
    }));
    setTableData(updatedData);
    setColumnCount(columnCount + 1);
  };

  const handleDeleteColumn = (columnName) => {
    const updatedData = tableData.map((row) => {
      const { [columnName]: _, ...updatedRow } = row;
      return updatedRow;
    });
    setTableData(updatedData);
    setColumnCount(columnCount - 1);
  };

  const saveTableStructure = async () => {
    try {
      console.log(userId);

      // Count fields with the same text
      const fieldCounts = {};
      tableData.forEach((row) => {
        Object.keys(row).forEach((field) => {
          if (field !== 'id') {
            const value = row[field];
            if (fieldCounts[value]) {
              fieldCounts[value]++;
            } else {
              fieldCounts[value] = 1;
            }
          }
        });
      });

      console.log('Field Counts:', fieldCounts);
      const exceedingDuplicates = Object.entries(fieldCounts).filter(([value, count]) => count > allowedDuplicates);
      if (exceedingDuplicates.length > 0) {
        const notificationMessage = exceedingDuplicates
          .map(([value, count]) => `${value}: ${count}`)
          .join('\n');
        toast.error(`Duplicates exceeding the allowed number (${allowedDuplicates}) found for the following values:\n${notificationMessage}`);
      }

      // Check if allowedDuplicates is defined and within a valid range

      await db.collection('users').doc(userId).update({ "table_data": tableData });
      console.log('Table data saved successfully!');
      console.error('Invalid allowedDuplicates value:', allowedDuplicates);

    } catch (error) {
      console.error('Error saving table data:', error);
    }
  };


  return (
    <>
      <h1>Manage your databases</h1>
      <TextField
        type="number"
        aria-readonly="true"
        contentEditable="false"
        label="Allowed Duplicates"
        value={allowedDuplicates}
      />
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {Object.keys(tableData[0])
                .filter((column) => column !== 'id' && column !== 'manager') // Exclude id and manager columns
                .sort()
                .map((column, index) => (
                  <TableCell key={column}>
                    <TextField
                      defaultValue={column}
                      value={column}
                      onChange={(e) => handleColumnChange(e, column, index)}
                    />
                    <Button variant="outlined" onClick={() => handleDeleteColumn(column, index)}>
                      Delete {column}
                    </Button>
                  </TableCell>
                ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .filter((row) => row.manager && row.manager.some((managerEmail) => managerEmail === userEmail))
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  {Object.keys(row)
                    .filter((column) => column !== 'id' && column !== 'manager') // Exclude id and manager columns
                    .sort()
                    .map((column) => (
                      <TableCell key={column}>
                        <TextField
                          value={row[column]}
                          onChange={(e) => handleCellChange(e, row.id, column)}
                        />
                      </TableCell>
                    ))}
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleDeleteRow(row.id)}>
                      Delete Row
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Button sx={{ margin: '10px' }} variant="contained" onClick={handleAddRow}>
          Add Row
        </Button>
        <Button variant="contained" onClick={saveTableStructure}>
          Save Table
        </Button>
        <ToastContainer />
      </TableContainer>


    </>
  );
};

export default EditableTable;